<template>
  <div :class="isSP ? 'special--sp' : 'special--pc'">
    <!-- トップビジュアル -->
    <sp-visual v-if="isSP" class="special__visual--sp" :img="require('@/assets/img/visual/special_sp.jpeg')" title="特装車両・特殊車両・架装" />
    <pc-visual v-else class="special__visual--pc" :img="require('@/assets/img/visual/special_pc.jpeg')" title="特装車両・特殊車両・架装" />
    <!-- タイトル -->
    <h1 v-if="isSP" class="special__title--sp">特装車両・特殊車両・架装</h1>
    <h1 v-else class="special__title--pc">特装車両・特殊車両・架装</h1>
    <!-- 詳細情報 -->
    <sp-description v-if="isSP" class="special__description--sp" :main="description.main" :sub="description.sub" />
    <!-- 画像集 -->
    <div v-if="isSP" class="special__images--sp">
      <div class="special__images__top--sp">
        <v-img class="special__images__top__image--sp" :src="require('@/assets/img/special/image1.jpeg')" :aspect-ratio="204/306"  cover  />
      </div>
      <v-img class="special__images__bottom--sp" :src="require('@/assets/img/special/image2.jpeg')" :aspect-ratio="319/170"  cover  />
    </div>
    <div v-else class="special__images--pc">
      <div class="special__images__top--pc">
        <v-img class="special__images__top__image--pc" :src="require('@/assets/img/special/image1.jpeg')" :aspect-ratio="429/642"  cover  />
        <p class="special__images__top__text--pc">{{ description.main }}</p>
      </div>
      <v-img class="special__images__bottom--pc" :src="require('@/assets/img/special/image2.jpeg')" :aspect-ratio="780/418"  cover  />
    </div>
    <!-- スライドショー -->
    <sp-slideshow v-if="isSP" class="special__slideshow--sp" :imgs="slideshows" />
    <v-img v-else class="special__slideshow--pc" :src="require('@/assets/img/special/slideshow_pc.jpeg')" :aspect-ratio="1280/319"  cover />
    <!-- 問い合わせ -->
    <sp-contact v-if="isSP" :isRecruit="false" />
    <pc-contact v-else :isRecruit="false" />
    <!-- 最後の画像 -->
    <v-img v-if="isSP" class="tire__special--sp" :src="require('@/assets/img/special/bottom1_sp.jpeg')" :aspect-ratio="375/231"  cover />
    <v-img v-else class="tire__special--pc" :src="require('@/assets/img/special/bottom1_pc.jpeg')" :aspect-ratio="1280/561"  cover />
  </div>
</template>

<script>
import SpVisual from '@/components/common/visual/sp'
import PcVisual from '@/components/common/visual/pc'
import SpDescription from '@/components/common/description/sp'
import SpSlideshow from '@/components/common/slideshow/sp'
import SpContact from '@/components/common/contact/sp'
import PcContact from '@/components/common/contact/pc'

export default {
  name: 'special',
  components: { SpVisual, PcVisual, SpDescription, SpSlideshow, SpContact, PcContact },
  mounted () {
  },
  computed: {
    /**
     * @return {Boolean} SPデバイスかどうか
     */
    isSP () {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
      )
    },
    /**
     * @return {Object} 説明文
     */
    description () {
      return {
        main: '弊社は極東開発工業・日本トレクス(株)・オナン発電機の指定工場です。\n特装・特殊車両の整備修理はおまかせください。',
        sub: ''
      }
    },
    /**
     * @return {String[]} スライドショーの画像一覧
     */
    slideshows () {
      const number = 4
      return [...Array(number)].map((_, index) => require('@/assets/img/special/slideshow' + (index + 1) + '.jpeg'))
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

.special {
  width: 100%;
  &--sp {
    @extend .special;
    margin-top: $sp_header_height;
  }
  &--pc {
    @extend .special;
    margin-top: $pc_header_height;
  }
  &__title {
    font-weight: 500;
    text-align: center;
    &--sp {
      @extend .special__title;
      margin-top: 70px;
      font-size: 1.5rem;
    }
    &--pc {
      @extend .special__title;
      margin-top: 171px;
      font-size: 3.5rem;
    }
  }
  &__description {
    &--sp {
      @extend .special__description;
      margin-top: 55px;
    }
    &--pc {
      @extend .special__description;
      margin-top: 100px;
    }
  }
  &__images {
    &--sp {
      @extend .special__images;
      margin: 60px auto 0 auto;
    }
    &--pc {
      @extend .special__images;
    }
    &__top {
      &--sp {
        @extend .special__images__top;
        display: block;
        width: 204px;
        height: 306px;
        margin: 0 auto;
      }
      &--pc {
        @extend .special__images__top;
        display: flex;
        align-items: center;
        width: 80%;
        margin: 168px auto 0 auto;
      }
      &__image {
        &--pc {
          @extend .special__images__top__image;
          flex: initial;
          width: 429px;
          height: 642px;
        }
      }
      &__text {
        &--pc {
          @extend .special__images__top__text;
          flex: 1;
          margin: 0 0 0 75px;
          font-size: 2rem;
          white-space: pre-wrap;
        }
      }
    }
    &__bottom {
      display: block;
      &--sp {
        @extend .special__images__bottom;
        width: 319px;
        max-width: 90vw;
        height: 170px;
        max-height: 47.962382vw;
        margin: 79px auto 0 auto;
      }
      &--pc {
        @extend .special__images__bottom;
        width: 780px;
        height: 418px;
        margin: 165px auto 0 auto;
      }
    }
  }
  &__slideshow {
    &--sp {
      @extend .special__slideshow;
      margin-top: 60px;
    }
    &--pc {
      @extend .special__slideshow;
      width: 100vw;
      height: 22.7485vw;
      margin-top: 185px;
    }
  }
  &__bottom {
    width: 100vw;
    &--sp {
      @extend .special__bottom;
      height: 61.6vw;
    }
    &--pc {
      @extend .special__bottom;
      height: 43.828vw;
    }
  }
}
</style>
